<template>
  <div class="container checkout-container">
    <div class="row checkout-row">
      <div class="col-12 checkout-page-title">
        <h1 class="page-title mb-3">
          {{ $t('Checkout.CHECKOUT_TITLE') }}
        </h1>
      </div>
    </div>

    <div class="row checkout-form-row">
      <div class="col-12 col-lg-8 checkout-form-page">
        <checkout-form />
      </div>

      <div class="col-12 col-lg-4 d-none d-lg-block order-summary-page">
        <order-summary />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  const CheckoutForm = () => import('@/components/checkout/CheckoutForm')
  const OrderSummary = () => import('@/components/checkout/OrderSummary')

  export default {
    name: 'Checkout',

    components: {
      CheckoutForm,
      OrderSummary
    },

    computed: {
      ...mapGetters({ cartItemCounts: 'getCartItemCount' }),
      shopNowLink(){
        let routePath = '/products/';
        if( localStorage && localStorage.getItem('product_type') ){
          
          routePath += localStorage.getItem('product_type');
        }else{

          routePath += 'sandwich';
        }
        return routePath;
      },
    },

    created () {
      if (!this.cartItemCounts) {
        window.location.href = this.shopNowLink;
      }
    },
  }
</script>
